.rs-video-wrap{
    margin-top: -125px;
    z-index: -111;
    .video-item{
        background: url(../img/video/1.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;
        padding: 280px 0 395px;
    }
	.content-wrap {
		background: #f4f7ff;
	}
    .rs-requset{
		padding-left: 60px;
		padding-right: 60px;
       .from-control{
            width: 100%;
            max-width: 100%;
            opacity: 1;
            padding: 10px 18px;
            border: 1px solid #F1F5FB;
			outline: none;
            &:active,
            &:focus {
              outline: none;
            }
        }
        textarea {
            height: 120px;
        }
        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: #454545;
          opacity: 1;
        }
        ::-moz-placeholder { /* Firefox 19+ */
          color: #454545;
          opacity: 1;
        }
        :-ms-input-placeholder { /* IE 10+ */
          color: $whiteColor;
          opacity: 1;
        }
        :-moz-placeholder { /* Firefox 18- */
          color: #454545;
          opacity: 1;
        } 
    }
    &.style2{
        margin-top: -380px;
        z-index: -111;
        .rs-requset{
            padding: 154px 60px;
        }
    }
    &.style3{
        margin-top: unset;
        z-index: unset;
        .grdiant-bg{
            background-image: linear-gradient(160deg, #03228F 0%, #4E95ED 100%);
        }
        .rs-requset{
            padding: 40px 60px;
            background: transparent;
           .from-control{
                padding: 15px 15px 15px 15px;
                border-radius: 5px 5px 5px 5px;
                color: #666666;
                border-style: solid;
                border-width: 0px 0px 0px 0px;
                border-color: #EFEFEF;
                background-color: #FFFFFF;
            }
            textarea {
                height: 120px;
            }
            ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
              color: #666666;
              opacity: 1;
            }
            ::-moz-placeholder { /* Firefox 19+ */
              color: #666666;
              opacity: 1;
            }
            :-ms-input-placeholder { /* IE 10+ */
              color: #666666;
              opacity: 1;
            }
            :-moz-placeholder { /* Firefox 18- */
              color: #666666;
              opacity: 1;
            } 
        }
    }
	.video-icon {
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		text-align: center;
		transform: translateY(-50%);
		& a{
			height: 80px;
			width: 80px;
			background: linear-gradient(180deg, #03228f 0%, #0b70e1 100%);
			display: inline-block;
			border-radius: 50%;
			line-height: 80px;
			text-align: center;
			font-size: 16px;
			transition: .3s;
			color: #fff;
			position: relative;
		}
	}
	.video-icon a::before {
		content: '';
		display: inline-block;
		position: absolute;
		top: -2px;
		left: -2px;
		bottom: -2px;
		right: -2px;
		border-radius: inherit;
		border: 1px solid $secondaryColor;
		animation: btnIconRipple 2s cubic-bezier(0.15, 1, 0.24, 1) both infinite;
	}
}

.modal-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    z-index: 1000000;
    cursor: pointer;
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
    -moz-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-duration: .3s;
    -moz-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-name: modal-video;
    -moz-animation-name: modal-video;
    animation-name: modal-video;
    -webkit-transition: opacity .3s ease-out;
    -moz-transition: opacity .3s ease-out;
    transition: opacity .3s ease-out;
    .modal-video-body {
        max-width: 960px;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        padding: 0 10px;
        display: table;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        .modal-video-inner {
            display: table-cell;
            vertical-align: middle;
            width: 100%;
            height: 100%;
            .modal-video-movie-wrap {
                width: 100%;
                height: 0;
                position: relative;
                padding-bottom: 56.25%;
                background-color: #333;
                -webkit-animation-timing-function: ease-out;
                -moz-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
                -webkit-animation-duration: .3s;
                -moz-animation-duration: .3s;
                animation-duration: .3s;
                -webkit-animation-name: modal-video-inner;
                -moz-animation-name: modal-video-inner;
                animation-name: modal-video-inner;
                -webkit-transform: translate(0);
                -moz-transform: translate(0);
                transform: translate(0);
                -webkit-transition: -webkit-transform .3s ease-out;
                -moz-transition: -moz-transform .3s ease-out;
                transition: -webkit-transform .3s ease-out;
                -moz-transition: transform .3s ease-out,-moz-transform .3s ease-out;
                transition: transform .3s ease-out;
                transition: transform .3s ease-out,-webkit-transform .3s ease-out,-moz-transform .3s ease-out;
                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
                .modal-video-close-btn {
                    position: absolute;
                    z-index: 2;
                    top: -45px;
                    right: 0;
                    display: inline-block;
                    width: 35px;
                    height: 35px;
                    overflow: hidden;
                    border: none;
                    background: transparent;
                    &:before,
                    &:after {
                        content: "";
                        position: absolute;
                        height: 2px;
                        width: 100%;
                        top: 50%;
                        left: 0;
                        background: #fff;
                        -webkit-border-radius: 5px;
                        border-radius: 5px;
                        margin-top: -6px;
                    }
                    &:before {
                        -webkit-transform: rotate(45deg);
                        -moz-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                    &:after {
                        -webkit-transform: rotate(-45deg);
                        -moz-transform: rotate(-45deg);
                        transform: rotate(-45deg);
                    }
                }
            }            
        }
    }
}

@media #{$lg} {
	.rs-video-wrap.style3 .rs-requset {
		padding: 40px 40px;
	}
}

@media #{$md} {
    .rs-video-wrap {
        margin-top: 0;
        .rs-requset {
            padding: 70px 50px;
        }
    }
}

@media #{$sm} {
	.rs-video-wrap {
		&.style2 {
			.rs-requset {
    			padding: 80px 40px;
			}
		}
	}
}

@media #{$xs} {
    .rs-video-wrap {
        .rs-requset {
            padding: 60px 20px;
        }
		&.style2 {
			.rs-requset {
    			padding: 40px 15px;
			}
		}
        &.style3 {
        .rs-requset {
        	padding: 40px 15px;
        }
    	}
    	.video-item {
    		padding: 200px 0 315px;
    	}
    }
}